import React from "react";
import {
  SigninContainer,
  SigninWrapper,
  SigninP,
  SigninInput,
} from "./SigninElements";
import { Button } from "../ButtonElements";

const Signin = () => {
  return (
    <SigninContainer>
      <SigninWrapper className="py-5 px-5">
        <div className="my-auto">
          <h3 className="center-align mb-5">Contact us</h3>
          <form>
            <SigninP>Email</SigninP>
            <SigninInput type="text" placeholder="Enter your email..." />
            <SigninP>Company name</SigninP>
            <SigninInput type="text" placeholder="Enter your Company name..." />
            <SigninP>Phone number</SigninP>
            <SigninInput type="text" placeholder="Enter your phone number..." />

            <SigninP>Company info</SigninP>

            <SigninInput type="textbox" placeholder="Company information..." />
            <Button className="mt-5 mb-3">Start</Button>
          </form>
          <p className="center-align">
            We usually get in touch during 2-3 business days.<br />
            We also may contact you by phone, so stay alerted.
          </p>
        </div>
      </SigninWrapper>
    </SigninContainer>
  );
};

export default Signin;
