import React, { useState } from "react";
import HeroSection from "../components/heroSection";
import InfoSection from "../components/infoSection";
import { Navbar } from "./../components/navbar";
import { Sidebar } from "./../components/sidebar";

import Image1 from "../images/design-notes.svg";
import Image2 from "../images/space.svg";
import InfoSectionLight from "../components/infoSectionLight";
import Services from "../components/services";
import Footer from "../components/footer";

export const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <InfoSection
        image={Image1}
        id="about"
        subtitle="Web development"
        title="Website for your company"
        text="A website is one of the most imporant tool for companies in our modern society. It has never been this easier for clients to contact and reach their desired company by visting their websites. Get yours today!"
        btnText="Start Today"
        
      />
      <InfoSectionLight
        image={Image2}
        id="discover"
        subtitle="SMS Gateway"
        title="Reach your clients using our SMS Service"
        text="Having a hard time reaching your customers? Want an easy, cheap and effecient way to reach them. Well don't think twice and use our affordable SMS gateway to send SMS to your clients just from your web browser. Ps. We have to verify the SMS/Email you're about to send due to security purposes, read more in our Terms of service."
        btnText="Start Today"
      />
      <Services />
      <InfoSectionLight
        image={Image1}
        id="#"
        subtitle="Email campaigning"
        title="Send campagins and offers through Emails"
        text="Send campagins and offers to your clients by emails. With own customized domain and a professional templated created just for your company. Ps. We have to verify the SMS/Email you're about to send due to security purposes, read more in our Terms of service."
        btnText="Start Today"
      />
      <Footer />
    </>
  );
};
